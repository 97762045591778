import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import Router from '@allvue/common/src/everestStyleComponents/router/Router';
import { configurePopup } from '@allvue/common/src/everestStyleComponents/popup/Popup';
import Routing from '@allvue/common/src/helpers/routing';
import getStore from '@allvue/common/src/store/createStore';
import ErrorHandler from '@allvue/common/src/everestStyleComponents/errorHandler/ErrorHandler';
import Configuration from '@allvue/common/src/helpers/configuration';

const root = document.querySelector('#root');
const popup = document.querySelector('#popup');

configurePopup(popup);

Configuration.initializeWithSingleResourceIdentifier();

const Main = React.lazy(() => import('./scenes/main/Main'));
ReactDOM.render(
  <Provider store={getStore()}>
    <Router history={Routing.history}>
      <ErrorHandler>
        <Main />
      </ErrorHandler>
    </Router>
  </Provider>,
  root
);
